<template>
<div class="team">
  <div style="margin-top:100px; margin-bottom:60px">
    <b-container>
      <b-row align-h="center">
        <b-col class="mx-2" md>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="1" />
        <b-col class="mx-2" md>
          <b-row align-h="center">
            <b-col sm>
              <TeamMemberCard :member="Romain" />
            </b-col>
            <b-col sm>
              <TeamMemberCard :member="WinterNis" />
            </b-col>
            <b-col sm>
              <TeamMemberCard :member="Tar" />
            </b-col>
          </b-row>
        </b-col>
        <b-col md="1" />
      </b-row>
    </b-container>
  </div>
  <div style="margin-top:150px">
    <b-container>
      <b-row align-h="center">
        <b-col class="mx-2" md>
        </b-col>
      </b-row>
    </b-container>
  </div>
</div>
</template>
<script>
import Vue from 'vue'
import TeamMemberCard from '@/components/teamMemberCard'
import {
  BContainer,
  BRow,
  BCol
} from 'bootstrap-vue'

export default Vue.extend({
  name: 'TopBar',
  components: {
    BContainer,
    BRow,
    BCol,
    TeamMemberCard
  },
  props: {
    msg: String
  },
  data () {
    return {
      Tar: {
        name: 'Tar',
        desc: '<p>Computer Scientist (PhD)</p><p>Blockchain expert</p><p>Photography &amp; Bouldering Enthousiast</p> ',
        photo: require('@/assets/img/tar.jpg')
      },
      WinterNis: {
        name: 'WinterNis',
        desc: '<p>Site reliability Engineer (SRE)</p><p>Infrastructure Engineering</p><p>Drummer &amp; Progressive Metal Fan</p>',
        photo: require('@/assets/img/winternis.jpg')
      },
      Romain: {
        name: 'Romain',
        desc: '<p>Data Scientist (PhD)</p><p>Crypto Enthousiast</p><p>Rocket League Fanatic</p>',
        photo: require('@/assets/img/romain.jpg')
      }
    }
  }
})
</script>
