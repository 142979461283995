<template>
<div class="team-member mt-3">
  <!-- <b-card no-body :img-src="banner" img-top > -->
  <b-card no-body no-image >
    <b-row class="justify-content-center mb-4">
      <b-col cols="2"/>
      <b-col cols="8"   align="center">
        <div class="card-profile-image">
            <b-img width="100px" :src="member.photo" rounded="circle" />
        </div>
      </b-col>
      <b-col  cols="2"/>
    </b-row>
    <b-card-body class="pt-0">
      <div class="text-center">
        <h5 class="h3">
          {{member.name}}
          <!-- <span class="font-weight-light">, 27</span> -->
        </h5>
        <div class="h5 font-weight-300">
          <i class="ni location_pin mr-2"></i>Lyon, France
        </div>
      </div>
      <b-row>
        <b-col>
          <div class="card-profile-stats d-flex justify-content-center">
              <span class="heading" v-html="member.desc" />
          </div>
        </b-col>
      </b-row>
        </b-card-body>
  </b-card>
</div>
</template>

<script>
import Vue from 'vue'
import {
  BRow,
  BCol,
  BImg,
  BCard,
  // BCardHeader,
  BCardBody
} from 'bootstrap-vue'
export default Vue.extend({
  name: 'TopBar',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    // BCardHeader,
    BCardBody
  },
  props: {
    member: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      banner: require('@/assets/img/banner.png'),
      dark: false
    }
  },
  methods: {
    selectMember () {
      return 0
    }
  }
})
</script>
<style lang="scss">
@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
      }

        // .card {
          // width:0%;
          // margin-top: 20px;
        // }
</style>
